<template>
  <footer class="footer">
    <section class="footer-section">
      <div class="footer-title">Contactanos en nuestras redes sociales:</div>
      <div class="footer-social-links">
        <a href="/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
            class="bi bi-facebook footer-icon me-3" viewBox="0 0 16 16">
            <path
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
          </svg>
        </a>
        <a href="https://www.instagram.com/ancestralnature.0/?hl=es-la" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
            class="bi bi-instagram footer-icon" viewBox="0 0 16 16">
            <path
              d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
          </svg>
        </a>
      </div>
    </section>
    <section class="footer-info">
      <div class="text-center text-md-start">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <h6 class="text-uppercase fw-bold mb-4 footer-title">
              Ancestal Nature
            </h6>
            <p class="footer-text">
              En nuestra tienda, te invitamos a descubrir una amplia gama de productos cuidadosamente seleccionados para
              tu salud y bienestar físico y emocional. Desde remedios naturales para aliviar el dolor hasta productos para
              el cuidado de la piel y suplementos nutritivos, tenemos todo lo que necesitas para sentirte bien por dentro
              y por fuera.
            </p>
          </div>
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <h6 class="text-uppercase fw-bold mb-4 footer-title">
              Enlaces útiles
            </h6>
            <p>
              <router-link class="footer-links" to="/">Inicio</router-link>
            </p>
            <p>
              <router-link class="footer-links" to="/products">Productos</router-link>
            </p>
            <p>
              <router-link class="footer-links" to="/about">Nosotros</router-link>
            </p>
          </div>
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <h6 class="text-uppercase fw-bold mb-4 footer-title">
              Contactanos
            </h6>
            <p class="footer-text">Retiro Ant, Villa Elena</p>
            <p class="footer-text">
              <a class="footer-links" href="mailto:ancestralnature.0@gmail.com">ancestralnature.0@gmail.com</a>
            </p>
            <p class="footer-text">
              <a class="footer-links" href="https://api.whatsapp.com/send?phone=573192912590&text=Hola" target="_blank">
                +57 3192912590
              </a>
              -
              <a class="footer-links"
                href="https://api.whatsapp.com/send?phone=573116306699&text=Hola%20me%20interesan%20los%20productos%20"
                target="_blank">+57 3116306699</a>
              -
              <a class="footer-links"
                href="https://api.whatsapp.com/send?phone=573206907990&text=Hola%20me%20interesan%20los%20productos%20"
                target="_blank">+57 3206907990</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="footer-info">
      <p class="footer-text">
        © 2024 Ancestral Nature - Todos los derechos reservados.
      </p>
    </div>
  </footer>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@import "../assets/scss/main.scss";

.footer {
  height: 100px;
  background-color: $green-primary;
  border-top: 1px solid $an-green;
}

.footer-section {
  border-bottom: 1px solid $an-green;
}

.footer-icon {
  color: $an-white;

  &:hover {
    color: $an-green;
  }
}

.footer-info {
  background-color: $green-primary;
  border-bottom: 1px solid $an-green;
}

.footer-title {
  color: $an-white;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
}

.footer-text {
  color: $an-white;
  text-shadow: 1px 1px 2px black;
}

.footer-links {
  color: $an-white;
  text-decoration: none;
  text-shadow: 1px 1px 2px black;

  &:hover {
    color: $an-green;
  }
}
</style>