<template>
  <v-carousel
    class="carousel"
    v-if="vueCarousel"
    show-arrows="hover"
    cycle
    :continuous="true"
  >
    <v-carousel-item v-for="(image, i) in props.images" :key="i">
      <img :src="image.url" :alt="image.id" />
    </v-carousel-item>
  </v-carousel>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const vueCarousel = ref(false);
const props = defineProps({
  images: Array,
});

onMounted(() => {
  if (props.images.length > 0) {
    vueCarousel.value = true;
  }
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/main";

.v-carousel {
  // Agregar estilos personalizados aquí
}

.bi-chevron-left,
.bi-chevron-right {
  color: $an-white;
}
</style>