<template>
  <header class="nav navbar navbar-expand-lg fixed-top">
    <div class="container-fluid">
      <router-link class="navbar-brand d-flex justify-center align-center" to="/" aria-label="Inicio">
        <div class="nav-container__img">
          <svg :class="[sizeImg ? 'nav-img__small' : 'nav-img']" class="rounded-1">
            <image :class="[sizeImg ? 'nav-img__small' : 'nav-img']" class="rounded-1" xlink:href="../assets/img/logo.jpg"
              src="../assets/img/logo.jpg" alt="Logo de Ancestral Nature" />
          </svg>
        </div>
        <div class="d-flex align-center big-title">
          <p>Ancestral Nature</p>
        </div>
      </router-link>
      <div class="d-flex flex-row-reverse pr-4">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="justify-md-content-end align-md-content-center d-flex align-center mr-2">
          <router-link class="me-3 shop-car" to="shop" aria-label="Tienda">
            <svg-icon v-if="amount <= 0" type="mdi" :path="mdiCartOutline"
              aria-label="Carrito de compras vacío"></svg-icon>
            <v-badge v-if="amount > 0" :content="amount" class="nav-babge">
              <svg-icon type="mdi" :path="mdiCartOutline" aria-label="Carrito de compras"></svg-icon>
            </v-badge>
          </router-link>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <router-link class="me-3" to="/" aria-label="Inicio">Inicio</router-link>
            <router-link class="me-3" to="/products" aria-label="Productos">Productos</router-link>
            <router-link class="me-3" to="/about" aria-label="Nosotros">Nosotros</router-link>
            <router-link class="me-3 shop-car-toogle" to="shop" aria-label="Tienda">
              <svg-icon v-if="amount <= 0" type="mdi" :path="mdiCartOutline"
                aria-label="Carrito de compras vacío"></svg-icon>
              <v-badge v-if="amount > 0" :content="amount">
                <svg-icon type="mdi" :path="mdiCartOutline" aria-label="Carrito de compras"></svg-icon>
              </v-badge>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCartOutline } from "@mdi/js";
import { useStore } from "vuex";

const store = useStore();
const amount = computed(() => store.getters.totalAmount);

let sizeImg = ref(false);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

function handleScroll() {
  sizeImg.value = window.scrollY > 1;
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/main.scss";

.nav {
  background-color: $green-primary;
  color: $an-orange;

  &-img {
    height: 6.25rem;
    width: 6.25rem;
    transition: all 0.1s ease-out;

    &__small {
      transition: all 1s ease-out;
      height: 50px;
      width: 86px;
    }
  }

  .shop-car {
    @media (min-width: 990px) {
      display: none;
    }
  }

  .shop-car-toogle {
    @media (max-width: 990px) {
      display: none;
    }
  }
}

.nav a,
p {
  p {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  color: white;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: $an-green;
  }
}

.big-title {
  p {
    font-size: 32px !important;
    letter-spacing: 2px;
    word-spacing: 2px;
  }
}

.nav a.router-link-exact-active {
  color: $an-green;
}
</style>