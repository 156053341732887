<template>
  <section class="section py-8">
    <div class="mb-8 container">
      <h1 class="mb-6 section-title">Nuestras líneas de negocio</h1>
      <p class="text-center container">
        Explora nuestra tienda y déjate inspirar por la belleza y la eficacia de
        nuestros productos naturales. Nuestro equipo está aquí para ayudarte en
        cada paso del camino, brindándote información y asesoramiento
        personalizado para que encuentres los productos perfectos para tus
        necesidades.
      </p>
    </div>
    <div class="container d-flex flex-wrap justify-content-between mt-0">
      <div class="section-products">
        <a href="/just">
          <img class="section-products__img img-fluid" src="../assets/img/Mariu Salud y Bienestar.jpg"
            alt="Productos Just" />
        </a>
        <div>
          <h4 class="section-text my-4 section-title"><a href="/just">Just</a></h4>
          <p class="section-paragraph">
            Explora los productos Just, basados en recetas ancestrales con
            hierbas medicinales de los Alpes Suizos. Nuestros productos ofrecen
            bienestar y belleza mediante aceites esenciales y extractos de
            plantas naturales, fusionando tradición con innovación para una
            experiencia única.
          </p>
        </div>
      </div>
      <div class="section-products">
        <img class="section-products__img img-fluid" src="../assets/img/nabico-producs.png" alt="Productos Nabico" />
        <div>
          <h4 class="section-text my-4 section-title">Nabico</h4>
          <p class="section-paragraph">
            Explora los productos Nabico, Nabico es una marca 100% Colombiana
            que toma lo mejor del Cannabis Sativa para crear formulas únicas y
            entregarte productos. NATURALES, NOVEDOSOS Y EFECTIVOS.
          </p>
        </div>
      </div>
      <div class="section-products">
        <img class="section-products__img img-fluid" src="../assets/img/miel.jpg" alt="Miel de abeja" />
        <div>
          <h4 class="section-text my-4 section-title">Miel de abeja</h4>
          <p class="section-paragraph">
            ¡Descubre la pureza y los beneficios naturales de nuestra exquisita
            miel de abeja orgánica! Sumérgete en su dulce sabor y aprovecha sus
            poderosas propiedades antioxidantes y antibacterianas para mejorar
            tu bienestar.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@import "../assets/scss/main";

.section {
  background-color: $an-white;

  &-products {
    margin: 0 10px;
    max-width: 300px;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    &__img {
      border-radius: 100%;
      width: 234.49px;
      height: 234.49px;
      border-radius: 100%;
      background: $green-primary;
    }
  }

  &-title {
    @extend .big-title;
    color: $green-primary;
  }

  &-text {
    font-family: Open Sans;
    font-size: 2rem;

    a {
      font-family: Open Sans;
      font-size: 2rem;
      color: #83a781;
      text-decoration: none;
    }
  }

  &-paragraph {
    color: $an-green;
  }
}
</style>