<template>
  <div>
    <LoadingComponent />
    <MyNavbar />
    <router-view class="rout" />
    <MyFooter />
  </div>
</template>

<style lang="scss">
@import "./assets/scss/main.scss";

body {
  background-color: $an-white;
}

#app {
  font-family: "Open Sans";
  font-style: normal;
  font-variation-settings: "wdth" 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
.v-overlay__scrim {
  background: rgb(8 113 34 / 96%);
}
.rout {
  margin-top: 7.9rem;
}
</style>

<script setup>
import MyNavbar from "./components/MyNavbar.vue";
import MyFooter from "./components/MyFooter.vue";
import { useStore } from "vuex";
import { onMounted } from "vue";
import LoadingComponent from "./components/LoadingComponent.vue";

const store = useStore();

onMounted(() => {
  store.dispatch("valueDialog", true);
});
</script>
