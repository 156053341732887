import { createStore } from "vuex";

export default createStore({
  state: {
    dialog: false,
    loading: false,
    shopCar: {},
  },
  getters: {
    getDialog: (state) => state.dialog,
    getLoading: (state) => state.loading,
    getShopCar: (state) => state.shopCar,
    totalPrice(state) {
      return Object.values(state.shopCar).reduce(
        (acc, { total }) => acc + total,
        0
      );
    },
    totalAmount(state) {
      return Object.values(state.shopCar).reduce(
        (acc, { amount }) => acc + amount,
        0
      );
    },
  },
  mutations: {
    setDialog(state, value) {
      state.dialog = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setShopCar(state, product) {
      state.shopCar[product.title] = { ...product };
    },
    clearShopCar(state) {
      state.shopCar = {};
    },
  },
  actions: {
    valueDialog({ commit }, value) {
      commit("setDialog", value);
    },
    addShopCar({ commit, state }, product) {
      const existingProduct = state.shopCar[product.title];

      if (existingProduct) {
        existingProduct.amount += product.amount;
        existingProduct.total = existingProduct.amount * existingProduct.price;
      } else {
        product.total = product.amount * product.price;
        commit("setShopCar", product);
      }
    },
    clearShopCar({ commit }) {
      commit("clearShopCar");
    },
  },
  modules: {},
});