import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { vueFire } from "vuefire";
import { createVuetify } from "vuetify"; // Importa createVuetify desde 'vuetify/lib/framework'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "vuetify/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, fa } from "vuetify/iconsets/fa-svg";
import SvgIcon from '@jamescoyle/vue-icon';
import VueCryptojs from 'vue-cryptojs'


library.add(fas);
library.add(far);

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
});

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(vueFire)
  .use(vuetify)
  .use(VueCryptojs)
  .component('SvgIcon', SvgIcon)
  .mount("#app");
