<template>
  <section class="gallery" v-if="viewGallery">
    <div class="d-flex flex-column flex-md-row flex-wrap justify-content-between">
      <div class="gallery-container" v-for="(image, i) in props.images" :key="i">
        <img
          :src="image.url"
          class="gallery-container__img img-fluid"
          :alt="image.id"
          @click="openImageOverlay(i)"
        />
      </div>
    </div>
    <div class="image-overlay" v-if="isOverlayVisible" @click="closeOverlay">
      <img :src="props.images[currentIndex].url" class="overlay-image" :alt="props.images[currentIndex].id" />
    </div>
  </section>
  <div v-else class="no-images">
    <p>No hay imágenes disponibles</p>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const viewGallery = ref(false);

const props = defineProps({
  images: Array,
});

const currentIndex = ref(null);
const isOverlayVisible = ref(false);

const openImageOverlay = (index) => {
  currentIndex.value = index;
  isOverlayVisible.value = true;
};

const closeOverlay = () => {
  isOverlayVisible.value = false;
};
if (props.images.length > 0) {
  store.dispatch("valueDialog", false);
  viewGallery.value = true;
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/main";

.gallery {
  background-color: $an-green;
  width: 100%;
}

.gallery-container {
  overflow: hidden;
  width: 25%;
  @media (max-width: 920px) {
    width: 100%;
  }

  &__img {
    width: 100%;
    height: 320px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.no-images {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
  color: $black;
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.overlay-image {
  max-width: 80%;
  max-height: 80%;
}
</style>